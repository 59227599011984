
import {defineComponent} from "vue";
import axios from "axios";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import OutlinesTable from "@/components/Dashboard/ProjectManagement/OutlinesTable/index.vue";
import {BACKEND_URL} from "@/constants";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "OutlinePostsPage",
  components: {
    NavBar,
    OutlinesTable,
    MainFooter,
  },
  data() {
    return {
      outlinePosts: [],
    };
  },
  methods: {
    fetchData() {
      const token = localStorage.getItem("token");

      axios.get(`${BACKEND_URL}/outline/outline-posts/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
          .then(response => {
            this.outlinePosts = response.data;
            console.log("Data updated:", response.data);
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
    }
  },
  mounted() {
    this.fetchData();


    setInterval(() => {
      this.fetchData();
    }, 20000);
  }
});
