
import { defineComponent } from "vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "ErrorPage",
  components: {
    MainFooter,
  },
});
