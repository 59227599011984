

import MainFooter from "@/components/Layouts/MainFooter.vue";
import MyProjects from "@/components/ProjectManagement/ProjectList/MyProjects.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default {
  name: "MyProjectsPage",
  components: {NavBar, MyProjects, MainFooter},
};
