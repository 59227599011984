
import { defineComponent } from "vue";
import PricingContent from "../components/Pricing/PricingContent.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "PricingPage",
  components: {
    PricingContent,
    MainFooter,
  },
});
