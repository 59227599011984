
import {defineComponent} from "vue";
import ProjectOverview from "../../components/ProjectManagement/Overview/ProjectOverview.vue";
import axios from 'axios';

import {BACKEND_URL} from "@/constants";
import MainFooter from "@/components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "PostPage",
  components: {
    MainFooter,
    ProjectOverview,
  },
  data() {
    return {
      data: [],
      exist: true,
    };
  },
  created() {
    const token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const slug = window.location.pathname.split('/').pop()

    const url = BACKEND_URL

    axios.get(`${url}/api/result/${slug}/`)
        .then(response => {
          this.data = response.data;
          if (response.data.status !== 'done') {
            this.exist = false
          }
        })
        .catch(error => {
          console.error('Error fetching requests:', error);
          this.exist = false
        });
  },
});
