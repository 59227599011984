
import {ref} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';
import MainFooter from '@/components/Layouts/MainFooter.vue';
import {BACKEND_URL} from '@/constants';
import NavBar from "@/components/NavBar/NavBar.vue";

export default {
  name: 'ProjectOrderCreate',
  components: {NavBar, MainFooter},
  setup() {
    const router = useRouter();

    const projectName = ref<string>('');
    const projectDomain = ref<string>('');
    const projectInfo = ref<string>('');

    const submitForm = () => {

      const projectData = {
        name: projectName.value,
        domain: projectDomain.value,
        info: projectInfo.value,
      };

      const token = localStorage.getItem('token');

      axios
          .post(`${BACKEND_URL}/api/orders/user-projects-list/`, projectData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
            console.log('Project created:', response.data);
            router.push('/my-projects');
          })
          .catch(error => {
            console.error('Error creating project:', error);
            console.log('Error details:', error.response);
          });
    };

    const cancelForm = () => {
      projectName.value = '';
      projectDomain.value = '';
      projectInfo.value = '';
      router.push('/my-projects');
    };

    return {
      projectName,
      projectDomain,
      projectInfo,
      submitForm,
      cancelForm,
    };
  },
};
