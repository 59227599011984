<template>
  <!-- Progress bar -->
  <div class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active mt-4" id="project-1-tab-pane" role="tabpanel"
             aria-labelledby="project-1-tab" tabindex="0">
          <h3 class="text-center">Step 2: Article Information</h3>
          <div class="step-wrapper">
            <ul class="step-content ps-0 mb-4 list-unstyled d-flex justify-content-center">
              <li class="text-center">
                <span class="fw-medium text-dark fs-14">1</span>
                <p class="fw-medium text-dark fs-14 mt-2">Basic Information</p>
              </li>
              <li class="text-center">
                <span class="fw-medium text-white fs-14 active">2</span>
                <p class="fw-medium text-dark fs-14 mt-2">Article Settings</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Form content -->
  <div class="row justify-content-center">
    <div class="col-lg-8">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="card-title mb-3">
            <h3 class="fw-medium fs-18 mb-0">Article Settings</h3>
          </div>
          <form class="was-validated">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mb-4">
                  <label class="label">Main Keyword</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Main Keyword"
                      :value="formData.main_keyword"
                      @input="onInput($event.target.value, 'main_keyword')"
                      required
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group mb-4">
                  <label class="label">Additional Keywords</label>
                  <textarea
                      class="form-control"
                      placeholder="Write Additional Keywords here..."
                      cols="30"
                      rows="5"
                      :value="formData.additional_keyword"
                      @input="onInput($event.target.value, 'additional_keyword')"
                      required
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-4">
                  <label class="label">Additional Requirements</label>
                  <textarea
                      class="form-control"
                      placeholder="Write Additional Requirements here..."
                      cols="30"
                      rows="5"
                      :value="formData.additional_requirements"
                      @input="onInput($event.target.value, 'additional_requirements')"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Word Count</label>
                  <input
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="1000/4000/Fit as best possible"
                      :value="formData.word_count"
                      @input="onInput($event.target.value, 'word_count')"
                      required
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Select Country</label>
                  <select
                      class="form-select form-control"
                      :value="formData.selected_country"
                      @change="onInput(parseInt($event.target.value), 'selected_country')"
                      required
                  >
                    <option disabled value="">Select Country</option>
                    <option
                        v-for="country in projectData.countries"
                        :key="country.id"
                        :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Images Count</label>
                  <input
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="Images Count"
                      :value="formData.images_count"
                      @input="onInput($event.target.value, 'images_count')"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Select Language</label>
                  <select
                      class="form-select form-control"
                      :value="formData.selected_language"
                      @change="onInput(parseInt($event.target.value), 'selected_language')"
                      required
                  >
                    <option disabled value="">Select Language</option>
                    <option
                        v-for="lang in projectData.languages"
                        :key="lang.id"
                        :value="lang.id"
                    >
                      {{ lang.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-4">
                  <label class="label">Deadline</label>
                  <input
                      type="date"
                      class="form-control"
                      @change="onInput($event.target.value, 'dead_line')"
                      required
                  />
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Checkbox  -->
    <div class="col-lg-4">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <p class="fw-medium text-dark fs-14">Additional Settings</p>
          <form>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-check c-form-check">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="MetaDescription"
                      :checked="formData.meta_description"
                      @change="onInput($event.target.checked, 'meta_description')"
                  />
                  <label class="form-check-label text-body fs-14" for="MetaDescription">
                    Meta Description
                  </label>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-check c-form-check">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault1"
                      :checked="formData.brand"
                      @change="onInput($event.target.checked, 'brand')"
                  />
                  <label class="form-check-label text-body fs-14" for="flexCheckDefault1">
                    Brand
                  </label>
                  <div class="col-lg-12" v-if="formData.brand">
                    <div class="form-group mb-4">
                      <label class="label">Brand Name</label>
                      <input
                          type="text"
                          class="form-control"
                          placeholder="Enter brand name"
                          :value="formData.brand_name"
                          @input="onInput($event.target.value, 'brand_name')"
                      />
                    </div>
                    <div class="form-group mb-4">
                      <label class="label">Brand Description</label>
                      <input
                          type="text"
                          class="form-control"
                          placeholder="Enter brand description"
                          :value="formData.brand_description"
                          @input="onInput($event.target.value, 'brand_description')"
                      />
                    </div>
                    <div class="form-check c-form-check">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault2"
                          :checked="formData.include"
                          @change="onInput($event.target.checked, 'include')"
                      />
                      <label class="form-check-label text-body fs-14" for="flexCheckDefault2">
                        Include
                      </label>
                      <div class="col-lg-12" v-if="formData.include">
                        <div class="form-check c-form-check">
                          <input
                              class="form-check-input"
                              type="checkbox"
                              id="ToTitleCheckbox"
                              :checked="formData.to_title"
                              @change="onInput($event.target.checked, 'to_title')"
                          />
                          <label class="form-check-label text-body fs-14" for="ToTitleCheckbox">
                            To Title
                          </label>
                        </div>
                        <div class="form-check c-form-check">
                          <input
                              class="form-check-input"
                              type="checkbox"
                              id="DescriptionCheckbox"
                              :checked="formData.to_description"
                              @change="onInput($event.target.checked, 'to_description')"
                          />
                          <label class="form-check-label text-body fs-14" for="DescriptionCheckbox">
                            To Description
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'StepTwo',
  computed: {
    ...mapGetters(['projectData'])
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onInput(value, key) {
      this.$emit('updateFormData', {[key]: value});
    },
  },
};
</script>
