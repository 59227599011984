
import { defineComponent } from "vue";
import ProjectTable from "../../components/Dashboard/ProjectManagement/ProjectTable/index.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "ProjectManagementPage",
  components: {
    NavBar,
    ProjectTable,
    MainFooter,
  },
});
