import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectProgress = _resolveComponent("ProjectProgress")!
  const _component_CalendarContent = _resolveComponent("CalendarContent")!
  const _component_RunningProject = _resolveComponent("RunningProject")!
  const _component_AllArticles = _resolveComponent("AllArticles")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProjectProgress),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CalendarContent)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RunningProject)
      ])
    ]),
    _createVNode(_component_AllArticles)
  ], 64))
}