
import {defineComponent, ref, computed} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

import {GoogleLogin} from "vue3-google-login";

export default defineComponent({
  name: "SignIn",
  components: {GoogleLogin},
  setup() {
    const store = useStore();
    const router = useRouter();

    const username = ref('');
    const password = ref('');
    const rememberMe = ref(false);
    const loginError = computed(() => store.getters.getLoginError);

    const handleLogin = async () => {
      try {
        await store.dispatch('login', {
          username: username.value,
          password: password.value,
        });

        const token = localStorage.getItem("token");
        if (token) {
          await store.dispatch('fetchOrdersData', token);
          await store.dispatch('fetchAllUsers', token);
          await store.dispatch('fetchProjectData');
          await store.dispatch('fetchProductivityData');
        }

        router.push('/');
      } catch (error) {
        console.error("Error during login:", error);
      }
    };

    const callback = async (response: any) => {
      try {
        await store.dispatch('googleLogin', response.code);


        const token = localStorage.getItem("token");
        if (token) {
          await store.dispatch('fetchOrdersData', token);
          await store.dispatch('fetchAllUsers', token);
          await store.dispatch('fetchProjectData');
          await store.dispatch('fetchProductivityData');
        }

        router.push('/');
      } catch (error) {
        console.error("Error during Google login:", error);
      }
    };

    return {
      username,
      password,
      rememberMe,
      handleLogin,
      loginError,
      callback,
    };
  },
});
