<template>
  <div class="row justify-content-center">
    <div class="col-lg-4 col-sm-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4 m-2">
          <h4 class="fs-18 fw-medium">Content</h4>
          <p class="fs-14 border-bottom border-color pb-3 mb-3 text-body">
            A simple start for everyone
          </p>
          <h2 class="fs-30 mb-4">
            $00.00/ <sub class="fs-14 text-body fw-normal">Per month</sub>
          </h2>
          <ul class="mb-4 list-unstyled ps-0">
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Free Live Support</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">30 GB Disk Space</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Scalable Bandwidth</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Free Setup</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
          </ul>
          <router-link
              to="/content-order"
              class="default-btn text-decoration-none d-inline-block border-btn"
          >
            Sign Up Now
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-sm-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4 m-2">
          <h4 class="fs-18 fw-medium">Brand Marketing</h4>
          <p class="fs-14 border-bottom border-color pb-3 mb-3 text-body">
            A simple start for everyone
          </p>
          <h2 class="fs-30 mb-4">
            $49.99/ <sub class="fs-14 text-body fw-normal">Per month</sub>
          </h2>
          <ul class="mb-4 list-unstyled ps-0">
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Free Live Support</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">30 GB Disk Space</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Scalable Bandwidth</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-3">
              <span class="fs-14 fw-medium text-body">Free Setup</span>
              <vue-feather type="check" style="stroke: #2ed47e; width: 20px"></vue-feather>
            </li>
          </ul>
          <router-link
              to="/content-order"
              class="default-btn text-decoration-none d-inline-block border-btn"
          >
            Sign Up Now
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingContent",
};
</script>