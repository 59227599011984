
import {useRouter} from 'vue-router';
import {computed, onMounted, ref} from "vue";
import store from "@/store/store";
import ModalDelete from "@/components/Modals/ModalDelete.vue";
import axios from "axios";
import {BACKEND_URL} from "@/constants";

export default {
  name: "MyProjects",
  components: {
    ModalDelete,
  },

  setup() {
    const router = useRouter();

    const selectedProjectId = ref<string | null>(null);
    const selectedProjectName = ref<string | null>(null);

    const userProjectsData = computed(() => {
      const projects = store.getters["userProjectsData"];
      return Array.isArray(projects) ? projects.slice().reverse() : [];
    });

    const fetchUserProjectData = () => {
      store.dispatch("fetchUserProjectData");
    };

    const viewOrder = (order: any) => {
      store.commit('SET_CURRENT_ORDER', order);
    };

    const redirectToCreateProject = () => {
      router.push('/create-project');
    };

    const openDeleteModal = (id: string, name: string) => {
      selectedProjectId.value = id;
      selectedProjectName.value = name;
    };

    const deleteProject = async (id: string) => {
      try {
        const token = localStorage.getItem('token')

        const response = await axios.delete(`${BACKEND_URL}/api/orders/user-projects-list/${id}/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        fetchUserProjectData();
      } catch (error) {
        console.error(`Error deleting project with ID: ${id}`, error);
      }
    };

    onMounted(() => {
      fetchUserProjectData();
    });

    return {
      userProjectsData,
      redirectToCreateProject,
      selectedProjectId,
      selectedProjectName,
      openDeleteModal,
      deleteProject,
      viewOrder,
    };
  },
};
