
import {defineComponent} from "vue";
import MainFooter from "@/components/Layouts/MainFooter.vue";
import DesignerComponent from "@/components/Designer/Designer.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "DesignerPage",
  components: {NavBar, DesignerComponent, MainFooter},
})

