
import {defineComponent} from "vue";

import VerticalForm from "../../components/Form/FormLayout/VerticalForm.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "NewContentPage",
  components: {
    NavBar,
    VerticalForm,
    MainFooter,
  },
});
