import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dark-mode-btn" }
const _hoisted_2 = { class: "dark-version" }
const _hoisted_3 = { class: "form-check form-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _createElementVNode("input", {
          class: "form-check-input",
          type: "checkbox",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOnlySidebarLightMode && _ctx.toggleOnlySidebarLightMode(...args)))
        })
      ])
    ])
  ]))
}