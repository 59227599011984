<template>
  <button
    class="template-option rounded-circle"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasExample"
    aria-controls="offcanvasExample"
  >
    <img src="../../assets/images/icons/settings.svg" alt="settings" />
  </button>
</template>

<script>
export default {
  name: "SettingsOption",
};
</script>