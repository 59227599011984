import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "nav nav-tabs border-0 mb-4 user-tabs justify-content-center",
  id: "myTab",
  role: "tablist"
}
const _hoisted_2 = {
  key: 0,
  class: "nav-item border-0",
  role: "presentation"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-item border-0",
  role: "presentation"
}
const _hoisted_4 = {
  key: 2,
  class: "nav-item border-0",
  role: "presentation"
}
const _hoisted_5 = {
  key: 3,
  class: "nav-item d-flex align-items-center gap-1",
  role: "presentation"
}
const _hoisted_6 = {
  key: 4,
  class: "nav-item d-flex align-items-center gap-1",
  role: "presentation"
}
const _hoisted_7 = {
  key: 5,
  class: "nav-item d-flex align-items-center gap-1",
  role: "presentation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.userStatus === 'staff' || _ctx.userStatus === 'admin')
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/main-dashboard']) }]),
            to: "/main-dashboard",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Manager ")
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isWriter || _ctx.userStatus === 'admin')
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/performer']) }]),
            to: "/performer",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Writer ")
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isDesigner || _ctx.userStatus === 'admin')
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/designer']) }]),
            to: "/designer",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Designer ")
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    ((_ctx.userStatus === 'user' || _ctx.userStatus === 'staff' || _ctx.userStatus === 'admin') && !_ctx.isDesigner)
      ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/', '/new-content']) }]),
            to: "/",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" AI Content ")
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_vue_feather, {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            "data-bs-title": _ctx.tooltipAiContentText,
            type: "info",
            size: "16"
          }, null, 8, ["data-bs-title"])
        ]))
      : _createCommentVNode("", true),
    ((_ctx.userStatus === 'user' || _ctx.userStatus === 'admin') && !_ctx.isDesigner && !_ctx.isWriter)
      ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/all-orders','/content-order', '/create-project', '/my-projects']) }]),
            to: "/all-orders",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Custom Content ")
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_vue_feather, {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            "data-bs-title": _ctx.tooltipCustomContentText,
            type: "info",
            size: "16"
          }, null, 8, ["data-bs-title"])
        ]))
      : _createCommentVNode("", true),
    ((_ctx.userStatus === 'user' || _ctx.userStatus==='staff' || _ctx.userStatus === 'admin') && !_ctx.isDesigner)
      ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link border-0 rounded-0 fs-14", { active: _ctx.isActive(['/outline-posts','/new-outline-post'])}]),
            to: "/outline-posts",
            role: "tab"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Outlines ")
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_vue_feather, {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            "data-bs-title": _ctx.tooltipOutlinesText,
            type: "info",
            size: "16"
          }, null, 8, ["data-bs-title"])
        ]))
      : _createCommentVNode("", true)
  ]))
}