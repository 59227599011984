
import {defineComponent} from "vue";
import ProjectProgress from "@/components/Widgets/ProjectProgress.vue";
import CalendarContent from "@/components/Widgets/CalendarContent.vue";
import RunningProject from "@/components/ProjectManagement/Overview/RunningProject.vue";
import AllArticles from "@/components/Table/DataTable/AllArticles.vue";


export default defineComponent({
  name: "MainDashboard",
  components: {
    AllArticles,
    RunningProject,
    CalendarContent,
    ProjectProgress,
  },
});
