


import MainFooter from "@/components/Layouts/MainFooter.vue";
import {defineComponent} from "vue";
import Performer from "@/components/Performer/Performer.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "PerformerPage",
  components: {NavBar, Performer, MainFooter},

});
