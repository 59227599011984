import axios from "axios";
import {BACKEND_URL} from "@/constants";
import {Module, ActionContext} from 'vuex';

interface OrderState {
    data: Array<any>;
    currentOrder: any;
    projectData: Record<string, any>;
    productivityData: any;
    userProjectsData: any;
}

const contentOrderModule: Module<OrderState, any> = {
    state: {
        data: [],
        currentOrder: null,
        projectData: {},
        productivityData: {},
        userProjectsData: {},
    },
    getters: {
        dataOrders(state: OrderState) {
            return state.data;
        },
        currentOrder(state: OrderState) {
            return state.currentOrder;
        },
        projectData(state: OrderState) {
            return state.projectData;
        },
        productivityData(state: OrderState) {
            return state.productivityData;
        },
        userProjectsData(state: OrderState) {
            return state.userProjectsData;
        }
    },
    mutations: {
        SET_ORDERS(state: OrderState, orders: any) {
            state.data = orders;
        },
        SET_CURRENT_ORDER(state: OrderState, order: any) {
            state.currentOrder = order;
        },
        ADD_ORDER(state: OrderState, order: any) {
            state.data.push(order);
        },
        SET_PROJECT_LIST(state: OrderState, projectData: Record<string, any>) {
            state.projectData = projectData;
        },
        UPDATE_ORDER(state: OrderState, updatedOrder: any) {
            const index = state.data.findIndex(order => order.id === updatedOrder.id);
            if (index !== -1) {
                state.data.splice(index, 1, updatedOrder);
            }
        },
        SET_PRODUCTIVITY(state: OrderState, productivityData: Record<string, any>) {
            state.productivityData = productivityData;
        },
        SET_USER_PROJECTS_DATA(state: OrderState, userProjectsData: Record<string, any>) {
            state.userProjectsData = userProjectsData;
        }
    },
    actions: {
        async fetchOrdersData({commit, rootGetters}: ActionContext<OrderState, any>) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BACKEND_URL}/api/orders/`, config);
                commit('SET_ORDERS', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке заказов:', error);
            }
        },
        async createOrder({commit, rootGetters}: ActionContext<OrderState, any>, orderData: any) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                };
                const response = await axios.post(`${BACKEND_URL}/api/orders/`, orderData, config);
                commit('ADD_ORDER', response.data);
            } catch (error) {
                console.error('Ошибка при создании заказа:', error);
            }
        },
        async fetchProjectData({commit, rootGetters}: ActionContext<OrderState, any>) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BACKEND_URL}/api/orders/projects/`, config);
                commit('SET_PROJECT_LIST', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке списка проектов:', error);
            }
        },
        async updateOrder({commit, rootGetters}: ActionContext<OrderState, any>, {id, orderData}: {
            id: number,
            orderData: any
        }) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                };
                const response = await axios.put(`${BACKEND_URL}/api/order/${id}/`, orderData, config);
                commit('UPDATE_ORDER', response.data);
            } catch (error) {
                console.error('Ошибка при обновлении заказа:', error);
            }
        },
        async fetchCurrentOrder({commit, rootGetters}, slug: string) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BACKEND_URL}/api/order/${slug}/`, config);
                commit('SET_CURRENT_ORDER', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке текущего заказа:', error);
            }
        },
        async fetchProductivityData({commit, rootGetters}: ActionContext<OrderState, any>) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BACKEND_URL}/api/orders/productivity/`, config);
                commit('SET_PRODUCTIVITY', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке заказов:', error);
            }
        },
        async fetchUserProjectData({commit, rootGetters}) {
            try {
                const token = rootGetters['token'];
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${BACKEND_URL}/api/orders/user-projects-list/`, config);

                commit('SET_USER_PROJECTS_DATA', response.data)

            } catch (error) {
                console.error('Ошибка при получении данных проекта:', error);
            }
        },
    }
};

export default contentOrderModule;
