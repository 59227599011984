
import {defineComponent} from "vue";
import ActiveProject from "@/components/Dashboard/ProjectManagement/ActiveProject.vue";
import MainFooter from "@/components/Layouts/MainFooter.vue";


export default defineComponent({
  name: "ProjectDetailPage",
  components: {MainFooter, ActiveProject},

});
