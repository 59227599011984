
import {defineComponent, ref, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router"; // Импортируем useRoute
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {BACKEND_URL} from "@/constants";

export default defineComponent({
  name: "ActiveProject",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter()

    const token = localStorage.getItem('token')

    const editor = ClassicEditor;
    const editorData = ref<string>('');
    const editMode = ref<boolean>(false);

    const editorConfig = {
      ckfinder: {
        uploadUrl: `${BACKEND_URL}/api/upload_image/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };

    const currentOrder = computed(() => store.getters.currentOrder);
    const projectData = computed(() => store.getters.projectData);
    const username = computed(() => store.getters.username);
    const userProjectsData = computed(() => store.getters.userProjectsData);

    const subtypes = computed(() => {
      const selectedType = projectData.value.article_type.find((type: {
        id: number
      }) => type.id === currentOrder.value.article_type.id);
      return selectedType?.sub_type || [];
    });

    const articleSubTypeId = computed({
      get() {
        return currentOrder.value.article_sub_type ? currentOrder.value.article_sub_type.id : null;
      },
      set(value: number | null) {
        if (currentOrder.value.article_sub_type) {
          currentOrder.value.article_sub_type.id = value!;
        } else {
          currentOrder.value.article_sub_type = {id: value!};
        }
      }
    });

    const progressPercentage = computed(() => {
      if (!currentOrder.value || !projectData.value.status.length) {
        return 0;
      }
      const orderStatusIndex = projectData.value.status.findIndex((status: {
        name: string
      }) => status.name === currentOrder.value.status.name);
      if (orderStatusIndex === -1) {
        return 0;
      }
      return Math.round((orderStatusIndex) / (projectData.value.status.length - 1) * 100);
    });

    const toggleEdit = () => {
      editorData.value = currentOrder.value.content;
      editMode.value = !editMode.value;
    };

    const goBack = () => {
      router.go(-1)
    }

    const saveChanges = async () => {
      try {
        const orderData = {
          ...currentOrder.value,
          project: currentOrder.value.project.id,
          status: currentOrder.value.status.id,
          selected_country: currentOrder.value.selected_country.id,
          selected_language: currentOrder.value.selected_language.id,
          article_type: currentOrder.value.article_type.id,
          article_sub_type: articleSubTypeId.value,
          content: editorData.value,
          assigned_to: currentOrder.value.assigned_to?.id ?? null,
          designer: currentOrder.value.designer?.id ?? null,
        };

        await store.dispatch('updateOrder', {id: currentOrder.value.id, orderData});
        await store.dispatch('fetchOrdersData');
        await store.dispatch('fetchCurrentOrder', currentOrder.value.id);
        toggleEdit();
      } catch (error) {
        console.error("Error saving data:", error);
      }
    };


    const cancelEdit = () => {
      editMode.value = false;
    };

    onMounted(() => {
      store.dispatch('fetchUserProjectData');
      if (!currentOrder.value) {
        const slug = route.params.id; // Используем route для получения параметра
        store.dispatch('fetchCurrentOrder', slug);
      }
    });

    return {
      editor,
      editorData,
      editorConfig,
      editMode,
      currentOrder,
      projectData,
      username,
      userProjectsData,
      subtypes,
      articleSubTypeId,
      progressPercentage,
      toggleEdit,
      saveChanges,
      cancelEdit,
      goBack,
    };
  },
});
