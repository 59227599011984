
import {ref, computed} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

export default {
  name: 'AllOrders',
  setup() {
    const store = useStore();
    const router = useRouter();

    const dataOrders = computed(() => store.getters['dataOrders']);

    const sortBy = ref<string>('');
    const sortOrder = ref<'asc' | 'desc'>('asc');

    // Функция для сортировки
    const sortedOrders = computed(() => {
      return [...dataOrders.value].sort((a, b) => {
        const sortColumn = sortBy.value;
        if (!sortColumn) return 0;

        const orderMultiplier = sortOrder.value === 'asc' ? 1 : -1;

        const aValue = sortColumn === 'project' ? a.project.name
            : sortColumn === 'article' ? a.article_type?.name
                : sortColumn === 'deadline' ? a.dead_line
                    : sortColumn === 'status' ? a.status.name
                        : '';

        const bValue = sortColumn === 'project' ? b.project.name
            : sortColumn === 'article' ? b.article_type?.name
                : sortColumn === 'deadline' ? b.dead_line
                    : sortColumn === 'status' ? b.status.name
                        : '';

        return aValue > bValue ? orderMultiplier : aValue < bValue ? -orderMultiplier : 0;
      });
    });

    const viewOrder = (request: any) => {
      store.commit('SET_CURRENT_ORDER', request);
    };

    const redirectToContentOrder = () => {
      router.push('/content-order');
    };

    const statusText = (status: string): string => {
      const statusTextMap: Record<string, string> = {
        'New': 'New',
        'In Progress': 'In Progress',
        'Checked': 'Checked',
        'Done': 'Done',
        'Fixed': 'Fixed',
      };
      return statusTextMap[status] || 'Unknown';
    };

    const setSortColumn = (column: string) => {
      if (sortBy.value === column) {

        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortBy.value = column;
        sortOrder.value = 'asc';
      }
    };

    return {
      sortedOrders,
      viewOrder,
      redirectToContentOrder,
      statusText,
      setSortColumn,
      sortBy,
      sortOrder
    };
  }
};
