
import {defineComponent, ref, computed, onMounted} from "vue";
import {useStore} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  name: "PerformerEdit",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();


    const editor = ClassicEditor;
    const editorData = ref('');
    const editing = ref(false);

    const currentOrder = computed(() => store.getters.currentOrder);
    const isEditing = computed(() => editing.value);

    const toggleEdit = () => {
      if (editing.value) {

        editorData.value = '';
      } else {
        editorData.value = currentOrder.value.content;
      }
      editing.value = !editing.value;
    };

    const saveChanges = async () => {
      try {
        const orderData = {content: editorData.value};
        await store.dispatch('updateOrder', {id: currentOrder.value.id, orderData});
        await store.dispatch('fetchOrdersData');
        toggleEdit();
        await store.dispatch('fetchCurrentOrder', currentOrder.value.id);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    };

    const check = async () => {
      try {
        const orderData = {content: editorData.value, status: 3};
        await store.dispatch('updateOrder', {id: currentOrder.value.id, orderData});
        await store.dispatch('fetchOrdersData');
        toggleEdit();
        await store.dispatch('fetchCurrentOrder', currentOrder.value.id);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    };

    const goBack = () => {
      router.go(-1)
    }

    const cancelEdit = () => {
      toggleEdit();
    };

    onMounted(() => {
      if (!currentOrder.value) {
        const slug = route.params.id;
        store.dispatch('fetchCurrentOrder', slug);
      }
    });

    return {
      editor,
      editorData,
      editing,
      isEditing,
      toggleEdit,
      saveChanges,
      check,
      cancelEdit,
      currentOrder,
      goBack,
    };
  }
});
