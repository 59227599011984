import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import store from '@/store/store';

export const requireAuth = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const token = localStorage.getItem('token');
    if (token) {
        const isValid = await store.dispatch('verifyToken', token);
        if (isValid) {
            await store.dispatch('fetchOrGetUserData');
            next();
        } else {
            store.dispatch('logout');
            next('/sign-in');
        }
    } else {
        next('/sign-in');
    }
};
