
import {defineComponent, ref, onMounted, computed} from "vue";
import PreLoader from "./components/Layouts/PreLoader.vue";
import MainHeader from "./components/Layouts/MainHeader.vue";
import SettingsOption from "./components/Layouts/SettingsOption.vue";
import SettingsModal from "./components/Layouts/SettingsModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "App",
  components: {
    PreLoader,
    MainHeader,
    SettingsOption,
    SettingsModal,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const isCollapsed = computed(() => store.getters.isCollapsed);
    const status = computed(() => store.getters.status);

    onMounted(async () => {
      setTimeout(() => {
        isLoading.value = false;
      }, 2000);

      const userString = localStorage.getItem("user");
      let userData = { username: null, status: null };

      if (userString) {
        userData = JSON.parse(userString);
      }

      store.commit("SET_USER", userData);

      const token = localStorage.getItem("token");

      try {
        if (token) {
          await store.dispatch("fetchOrdersData", token);
          await store.dispatch("fetchAllUsers", token);
          await store.dispatch("fetchProjectData");
          await store.dispatch("fetchProductivityData");
          await store.dispatch("fetchOrGetUserData");
        } else {
          console.error("Отсутствует токен");
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
    });

    return {
      isLoading,
      isLoggedIn,
      isCollapsed,
      status,
    };
  },
});
