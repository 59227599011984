<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <h4 class="text-dark fs-18 fw-medium mb-3">New Content</h4>
      <form @submit.prevent="submitForm">
        <div class="form-group mb-4">
          <label class="label">Query</label>
          <input type="text" class="form-control" placeholder="Enter query" v-model="query" required/>
        </div>
        <div class="form-group mb-4">
          <label class="label">URL</label>
          <input type="text" class="form-control" placeholder="Enter URL" v-model="url" required/>
        </div>

        <div class="form-group mb-0 d-flex">
          <button type="submit" class="default-btn">Create</button>
          <button type="button" class="default-btn border-btn ms-3" @click="redirectToHome">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/constants';

export default {
  name: "NewOutlinePostForm",
  data() {
    return {
      query: "",
      url: ""
    };
  },
  methods: {
    async submitForm() {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const url = BACKEND_URL

        const requestData = {
          query: this.query,
          url: this.url,
        };

        const response = await axios.post(`${url}/outline/create-outline-post/`, requestData).then(response => {
          this.redirectToHome();
        });

        console.log('Submitted data:', requestData);
        console.log(response.data);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
    redirectToHome() {
      this.$router.push('/outline-posts');
    }
  }
};
</script>