import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import VueFeather from 'vue-feather';
import CKEditor from '@ckeditor/ckeditor5-vue';

import vue3GoogleLogin from 'vue3-google-login'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "./assets/custom.scss";
import store from "@/store/store";


const app = createApp(App).use(router);
app.use(store);
app.use(vue3GoogleLogin, {
    clientId:'959316943890-rgl7rg2jec6ra1jju6glg303d03ce15f.apps.googleusercontent.com'
})
app.use(CKEditor);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.component(VueFeather.name, VueFeather);
app.mount("#app");
