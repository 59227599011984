
import {defineComponent} from "vue";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import OnlyHeaderDarkModeBtn from "./OnlyHeaderDarkModeBtn.vue";
import OnlySidebarLightModeBtn from "./OnlySidebarLightModeBtn.vue";

export default defineComponent({
  name: "SettingsModal",
  components: {
    LightDarkSwtichBtn,
    OnlyHeaderDarkModeBtn,
    OnlySidebarLightModeBtn,
  },
});
