
import {defineComponent} from "vue";

import NewOutlinePostForm from "@/components/Form/FormLayout/NewOutlinePostForm.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import NavBar from "@/components/NavBar/NavBar.vue";

export default defineComponent({
  name: "NewOutlinePost",
  components: {
    NavBar,
    NewOutlinePostForm,
    MainFooter,
  },
});
