<template>
  <div class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <div class="card-title d-flex justify-content-between align-items-center mb-4">
        <h3 class="fw-medium fs-18 mb-0">Running Project</h3>
      </div>

      <ul class="ps-0 mb-0 list-unstyled">
        <li v-for="data in limitedSortedDataOrders" :key="data.id"
            class="d-flex justify-content-between border-bottom border-color mb-3 pb-3">
          <div class="w-50">
            <router-link :to="'/project-details/' + data.id" class="text-decoration-none" @click="viewOrder(data)">
              <span class="text-dark fs-14 d-block mb-2">{{ data.project.name }}</span>
            </router-link>
            <span class="text-dark fs-14 d-block">
              <vue-feather
                  type="calendar"
                  style="width: 16px; stroke: #919aa3; position: relative; top: -1.5px; margin-right: 6px;"
              ></vue-feather>
              Deadline: <span class="text-body">{{ data.dead_line }}</span>
            </span>
          </div>

          <div class="w-50 ms-3">
            <div class="progress rounded-0 mb-3" role="progressbar"
                 :aria-valuenow="progressPercentage(data)"
                 aria-valuemin="0" aria-valuemax="100" style="height: 10px; background-color: #e9ecef;">
              <div class="progress-bar bg-success" :style="{ width: progressPercentage(data) + '%' }"></div>
            </div>
            <span class="text-dark d-block text-end">
              <span class="text-success">{{ progressPercentage(data) }}%</span>
              of the total project is complete
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'RunningProject',
  computed: {
    ...mapGetters(['dataOrders', 'projectData']),
    filteredDataOrders() {
      return this.dataOrders.filter(order => order.status.name !== 'Done' && order.status.name !== 'Failed');
    },
    sortedDataOrders() {
      return this.filteredDataOrders.slice().sort((a, b) => new Date(a.dead_line) - new Date(b.dead_line));
    },
    limitedSortedDataOrders() {
      return this.sortedDataOrders.slice(0, 4);
    }
  },
  methods: {
    ...mapActions(['fetchProjectData']),
    viewOrder(data) {
      this.$store.commit('SET_CURRENT_ORDER', data);
    },
    progressPercentage(order) {
      if (!order || !this.projectData.status.length) {
        return 0;
      }

      const orderStatusIndex = this.projectData.status.findIndex(status => status.name === order.status.name);

      if (orderStatusIndex === -1) {
        return 0;
      }

      return Math.round((orderStatusIndex) / (this.projectData.status.length - 1) * 100);
    }
  },
};
</script>
