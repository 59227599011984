/* eslint-disable */

import token from "./authModule";
import axios from "axios";
import { BACKEND_URL } from "@/constants";
import { ActionContext } from 'vuex';

interface State {
    users: any[];
}

const usersModule = {
    state: {
        users: []
    },
    getters: {
        allUsers: (state: State) => state.users
    },
    mutations: {
        SET_ALL_USERS: (state: State, allUsers: any) => {
            state.users = allUsers;
        }
    },
    actions: {
        async fetchAllUsers({ commit }: ActionContext<State, any>, token: string) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BACKEND_URL}/api/users/`, config);
                commit('SET_ALL_USERS', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке заказов:', error);
            }
        }
    }
};

export default usersModule;
