import {createStore} from 'vuex';
import authModule from './modules/authModule'
import layoutModule from "@/store/modules/layoutModule";
import contentOrderModule from "./modules/contentOrderModule";
import contentManagementModule from "@/store/modules/contentManagementModule";
import usersModule from "@/store/modules/usersModule";


const store = createStore({
    modules: {
        auth: authModule,
        contentOrder: contentOrderModule,
        contentManagement: contentManagementModule,
        users:usersModule,
        layout: layoutModule,
    },
});

export default store;
