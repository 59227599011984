
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store/store";
import {useRoute} from "vue-router";
import {Tooltip} from 'bootstrap';


export default defineComponent({
  name: "NavBar",
  setup() {
    const route = useRoute()

    const tooltipAiContentText = ref("Discover the power of AI-generated content. Utilize advanced algorithms to enhance your writing process, streamline idea generation, and improve engagement. Whether for blogs, social media, or marketing, let AI assist you in crafting compelling narratives and captivating copy. ") || null;
    const tooltipCustomContentText = ref("Our writers craft unique pieces tailored to your specific needs. We understand that every project requires a personal touch, which is why each text is meticulously developed to align with your audience, goals, and style. Whether it's articles, blogs, marketing materials, or scripts, our expertise will help you effectively convey your message to your target audience with maximum impact.") || null;
    const tooltipOutlinesText = ref("Create structured outlines to keep your content organized and on track. Outlines simplify complex projects by breaking them into sections, making it easier to stay focused and deliver clear, impactful content.") || null;

    const userStatus = computed(() => store.getters['status']);
    const isWriter = computed(() => store.getters['is_writer']);
    const isDesigner = computed(() => store.getters['is_designer']);

    const isActive = (paths: string[]) => {
      return paths.includes(route.path);
    };

    onMounted(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
      });
    });

    return {
      isWriter,
      isDesigner,
      isActive,
      userStatus,
      tooltipAiContentText,
      tooltipCustomContentText,
      tooltipOutlinesText,
    }
  }
});
