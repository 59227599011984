<template>
  <div class="row justify-content-center">
    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Total Task: {{ dataOrders.length }}</h3>
              <span class="text-body">Awaiting processing</span>
              <h1 class="fw-medium fs-30 mb-3">{{ awaitingProcessing }}</h1>
              <div
                  class="progress rounded-0 mb-0 progress-width"
                  role="progressbar"
                  aria-label="Basic example"
                  :aria-valuenow="awaitingProcessingPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="height: 10px; background-color: #f4f6fa; width: 210px"
              >
                <div
                    class="progress-bar bg-success rounded-0"
                    :style="{ width: awaitingProcessingPercentage + '%', height: '10px' }"
                ></div>
              </div>
              <p class="fs-12 text-dark lh-1 mt-2">
                <span class="text-danger">{{ awaitingProcessingPercentage }}%</span> is waiting for processing
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                  src="../../assets/images/icons/project-icon-2.svg"
                  alt="project-icon-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Total Members: {{ allUsers.length }}</h3>
              <span class="text-body">Working hard</span>
              <h1 class="fw-medium fs-30 mb-3">{{ workingMembers }}</h1>
              <div
                  class="progress rounded-0 mb-0 progress-width"
                  role="progressbar"
                  aria-label="Basic example"
                  :aria-valuenow="engagementPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="height: 10px; background-color: #f4f6fa; width: 210px"
              >
                <div
                    class="progress-bar bg-warning rounded-0"
                    :style="{ width: engagementPercentage + '%', height: '10px' }"
                ></div>
              </div>
              <p class="fs-12 text-dark lh-1 mt-2">
                Project engagement <span class="text-success">{{ engagementPercentage }}%</span>
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                  src="../../assets/images/icons/project-icon-3.svg"
                  alt="project-icon-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Productivity: {{ productivity }}</h3>
              <span class="text-body">Revenue</span>
              <h1 class="fw-medium fs-30 mb-3">$ {{ productivityData.total }}</h1>
              <div
                  class="progress rounded-0 mb-0 progress-width"
                  role="progressbar"
                  aria-label="Basic example"
                  :aria-valuenow="revenuePercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="height: 10px; background-color: #f4f6fa; width: 210px"
              >
                <div
                    class="progress-bar bg-danger rounded-0"
                    :style="{ width: revenuePercentage + '%', height: '10px' }"
                ></div>
              </div>
              <p class="fs-12 text-dark lh-1 mt-2">
                Revenue earned this month
                <span class="text-success">{{ revenuePercentage }}%</span>
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                  src="../../assets/images/icons/project-icon-4.svg"
                  alt="project-icon-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProjectProgress",
  computed: {
    ...mapGetters(["dataOrders", "allUsers", "productivityData"]),
    awaitingProcessing() {
      return this.dataOrders.filter(order => order.status.name === 'New').length;
    },
    awaitingProcessingPercentage() {
      return this.dataOrders.length ? Math.round((this.awaitingProcessing / this.dataOrders.length) * 100) : 0;
    },
    workingMembers() {
      // Здесь вы можете добавить логику для вычисления работающих членов
      return this.allUsers.length; // пример
    },
    engagementPercentage() {
      // Здесь вы можете добавить логику для вычисления процента вовлеченности
      return 90; // пример
    },
    productivity() {
      // Здесь вы можете добавить логику для вычисления продуктивности
      return 90; // пример
    },
    revenue() {
      // Здесь вы можете добавить логику для вычисления дохода
      return "$1520"; // пример
    },
    revenuePercentage() {
      // Здесь вы можете добавить логику для вычисления процента дохода
      return 50; // пример
    },
  },
  async mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await this.fetchAllUsers(token);
        await this.fetchProductivityData();
      } catch (error) {
        console.error("Ошибка при загрузке пользователей:", error);
      }
    } else {
      console.error("Отсутствует токен");
    }
  },
  methods: {
    ...mapActions(["fetchAllUsers", "fetchProductivityData"]),
  },
};
</script>
