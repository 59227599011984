<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <div class="card-title d-flex justify-content-between align-items-center mb-3">
        <h3 class="fw-medium fs-18 mb-0">All Articles</h3>
        <select class="form-select border-0" aria-label="Default select example">
          <option selected>This Month</option>
          <option value="1">Last Month</option>
          <option value="2">This Year</option>
          <option value="3">Last Year</option>
        </select>
      </div>
      <div class="all-table scroll-bar h-524">
        <table class="table align-middle mb-0">
          <thead>
          <tr>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14 ps-0" scope="col">Article Name</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Project</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Client</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Performer</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Deadline</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Progress</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Status</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">Budget</th>
            <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="order in dataOrders" :key="order.id">
            <td class="ps-0">
              <router-link :to="'/project-details/' + order.id" class="text-decoration-none" @click="viewOrder(order)">
                <h5 class="fs-14 text-dark mb-0">
                  {{ order.article_type?.name }}
                </h5>
              </router-link>
            </td>
            <td>{{ order.project.name }}</td>
            <td>{{ order.user.first_name || order.user.username }}</td>
            <td v-if="order.assigned_to">
              {{
                order.assigned_to.user.first_name || order.assigned_to.user.username
              }}
            </td>
            <td v-else></td>
            <td>{{ order.dead_line }}</td>
            <td>
              <span class="text-body d-block mb-1">{{ progressPercentage(order) }}%</span>
              <div class="progress rounded-0 overflow-visible" role="progressbar"
                   :aria-valuenow="progressPercentage(order)" aria-valuemin="0" aria-valuemax="100"
                   style="height: 5px">
                <div class="progress-bar rounded-0 overflow-visible"
                     :style="{ width: progressPercentage(order) + '%', height: '5px' }"></div>
              </div>
            </td>
            <td>
              <span :class="statusClass(order.status.name)">{{ order.status.name }}</span>
            </td>
            <td>
              <span class="d-block text-body">$5</span>
              <span class="d-block text-body">Estimation</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted} from 'vue';
import {useStore} from 'vuex';

export default {
  name: "AllArticles",
  setup() {
    const store = useStore();

    const dataOrders = computed(() => store.getters.dataOrders);
    const projectData = computed(() => store.getters.projectData || {status: []});


    const viewOrder = (order) => {
      store.commit('SET_CURRENT_ORDER', order);
    };

    const progressPercentage = (order) => {
      if (!order || !projectData.value.status || !projectData.value.status.length) {
        return 0;
      }

      const currentStatusIndex = projectData.value.status.findIndex(
          (status) => status.name === order.status?.name
      );

      if (currentStatusIndex === -1) {
        return 0;
      }

      return Math.round((currentStatusIndex / (projectData.value.status.length - 1)) * 100);
    };


    const statusClass = (status) => {
      const statusClassMap = {
        'New': 'text-primary',
        'In Progress': 'text-warning',
        'Checked': 'text-info',
        'Done': 'text-success'
      };
      return statusClassMap[status] || 'text-secondary';
    };


    const fetchOrdersData = () => {
      store.dispatch('fetchOrdersData');
    }

    onMounted(() => {
      fetchOrdersData();

      const interval = setInterval(() => {
        fetchOrdersData();
      }, 30000);

      onMounted(() => {
        clearInterval(interval);
      })
    });

    return {
      dataOrders,
      viewOrder,
      progressPercentage,
      statusClass
    };
  }
};
</script>

<style scoped>
.text-primary {
  color: #007bff;
}

.text-warning {
  color: #ffc107;
}

.text-info {
  color: #17a2b8;
}

.text-success {
  color: #28a745;
}

.text-secondary {
  color: #6c757d;
}
</style>
