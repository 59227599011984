
import {defineComponent} from "vue";
import {mapGetters} from 'vuex';

export default defineComponent({
  name: "CalendarContent",
  data() {
    const date = new Date();
    const currYear = date.getFullYear();
    const currMonth = date.getMonth();
    return {
      currentDate: "",
      currYear,
      currMonth,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ] as string[],
    };
  },
  computed: {
    ...mapGetters(['dataOrders']), // Используем getter из Vuex
    calendarDays() {
      const currDate = new Date().getDate();
      const currMonth = new Date().getMonth();
      const currYear = new Date().getFullYear();
      const firstDayOfMonth = new Date(this.currYear, this.currMonth, 1).getDay();
      const lastDateOfMonth = new Date(this.currYear, this.currMonth + 1, 0).getDate();
      const lastDayOfMonth = new Date(this.currYear, this.currMonth, lastDateOfMonth).getDay();
      const lastDateOfLastMonth = new Date(this.currYear, this.currMonth, 0).getDate();
      const days: {
        date: number;
        class: string;
        events?: { project: { name: string }; article_type: { name: string } }[]
      }[] = [];

      // Добавляем последние дни предыдущего месяца
      for (let i = firstDayOfMonth; i > 0; i--) {
        const date = lastDateOfLastMonth - i + 1;
        days.push({date, class: "inactive"});
      }

      // Добавляем дни текущего месяца
      for (let i = 1; i <= lastDateOfMonth; i++) {
        const isToday = i === currDate && this.currMonth === currMonth && this.currYear === currYear;
        // Получаем события из dataOrders для текущей даты
        const dayEvents = this.dataOrders
            .filter((order: any) => {
              if (order.status.name === "Done" || order.status.name === "Failed") return
              const eventDate = new Date(order.dead_line); // Предполагается, что dead_line в dataOrders содержит дату
              return (
                  eventDate.getDate() === i &&
                  eventDate.getMonth() === this.currMonth &&
                  eventDate.getFullYear() === this.currYear
              );
            })
        days.push({date: i, class: isToday ? "active" : "", events: dayEvents});
      }

      // Добавляем первые дни следующего месяца
      for (let i = lastDayOfMonth; i < 6; i++) {
        const date = i - lastDayOfMonth + 1;
        days.push({date, class: "inactive"});
      }

      return days;
    },
  },
  mounted() {
    this.currentDate = `${this.months[this.currMonth]} ${this.currYear}`;
  },
  methods: {
    changeMonth(direction: number) {
      this.currMonth += direction;
      if (this.currMonth < 0 || this.currMonth > 11) {
        const date = new Date(this.currYear, this.currMonth, new Date().getDate());
        this.currYear = date.getFullYear();
        this.currMonth = date.getMonth();
      }
      this.currentDate = `${this.months[this.currMonth]} ${this.currYear}`;
    },
  },
});
