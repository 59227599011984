
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: 'MainHeader',
  setup() {
    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const username = computed(() => store.getters.username)
    const firstName = computed(() => store.getters.firstName)
    const status = computed(() => store.getters.status)
    const isCollapsed = computed(() => store.getters.isCollapsed)

    const logout = () => {
      store.dispatch('logout')
    }


    const toggleCollapsed = () => {
      store.commit('TOGGLE_COLLAPSED');
    }


    return {
      isLoggedIn,
      username,
      firstName,
      status,
      isCollapsed,
      toggleCollapsed,
      logout,
    }
  }
}


// import {defineComponent} from 'vue';
// import {mapMutations, mapGetters} from 'vuex';
//
// export default defineComponent({
//   name: 'MainHeader',
//   computed: {
//     ...mapGetters(['isLoggedIn', 'username','firstName', 'status', 'isCollapsed']),
//   },
//   methods: {
//     ...mapMutations(['TOGGLE_COLLAPSED']),
//
//     toggleCollapsed() {
//       this.TOGGLE_COLLAPSED();
//     },
//   },
// });
